import request from '@/utils/request'

export function downloadAPk() {
  return request({
    url: '/v1/vsa/download/apk',
    method: 'get',
    data: []
  })
}

// 获取支付列表
export function payConfig() {
    return request({
      url: '/v1/vsa/pay/config',
      method: 'get',
      data: []
    })
}


// 创建订单
export function createRecharge(params) {
    return request({
      url: '/v1/vsa/recharge/create',
      method: 'POST',
      data: params
    })
}

// 支付
export function payRecharge(params) {
    return request({
      url: '/v1/vsa/recharge/pay',
      method: 'POST',
      data: params
    })
}

// 充值状态
export function stateRecharge(id) {
    return request({
      url: '/v1/vsa/recharge/state?id=' + id,
      method: 'get'
    })
}

