<template>
    <div class="cz_box">
        <div v-if="!ismobile" class="cz_box1">
            <div class="cz_top">
                <img src="../../../assets/img/dl6.png" alt="">
                <div class="cz_but" @click="goLgin">
                    {{isLogin?'去充值':'去登录' }}
                </div>
            </div>
            <div class="cz_bottom">
                <div class="left_z">
                    <div class="left_title">
                        <img src="../../../assets/img/index01.png" alt="">
                    </div>
                    <div class="left_xz">
                        <div class="pg_cz" @mouseenter="handleMouseEnter" @mouseleave="handleMouseLeave">
                            <img  src="../../../assets/img/index03.png" alt="">
                        </div>
                        <div class="anz_cz"  @mouseenter="handleMouseEnter1" @mouseleave="handleMouseLeave1">
                            <img  src="../../../assets/img/index04.png" alt="">
                        </div>
                    </div>
                    <div class="left_ma">
                        <div >
                            <img :class="{'left_pg': isshow}" src="../../../assets/img/pgcode.png" alt="">
                        </div>
                        <div>
                            <img :class="{'left_anz': isshow1}" src="../../../assets/img/anzcode.png" alt="">
                        </div>
                    </div>
                </div>
                <div class="right_z">
                    <img src="../../../assets/img/index05.png" alt="">
                </div>
            </div>
        </div>
        <div class="mob_box" v-else>
            <div class="mob_login" @click="goLgin">
                <span>{{isLogin?'去充值':'去登录' }}</span>
            </div>
            <div class="mob_tu">
                <img src="../../../assets/img/sj01.png" alt="">
            </div>
            <div class="mob_an">
                <div>
                    <img @click="doDownload" src="../../../assets/img/sj02.png" alt="">
                </div>
                <div>
                    <img @click="doDownloadPg" src="../../../assets/img/sj03.png" alt="">
                </div>
            </div>
        </div>
    </div>
  </template>
  
  <script>
  import { getToken } from '@/utils/auth' // 判断是否登录
  import { downloadAPk } from '@/api/cz'
  export default {
    name: 'index',
    data() {
      return {
        isLogin: true,
        isshow: true,
        isshow1: true,
        ismobile: false
      }
    },
    created() {
        this.detectDevice()
        // 判断是否登录 
        if (getToken()) {
        this.isLogin = true
        } else  {
        this.isLogin = false
        }
    },
    methods: {
        goLgin() {
            this.$router.push('./login')
        },
        handleMouseEnter() {
            this.isshow = false
        },
        handleMouseLeave() {
            this.isshow = true
        },
        handleMouseEnter1() {
            this.isshow1 = false
        },
        handleMouseLeave1() {
            this.isshow1 = true
        },
        detectDevice () {
            // 获取userAgent字符串
            var userAgent = navigator.userAgent || navigator.vendor || window.opera
        
            // 使用正则表达式来检查userAgent字符串中是否包含某些关键字
            // 这些关键字通常与移动设备相关
            var mobile = /windows phone|iphone|ipad|ipod|android|blackberry|mini|windows ce|palm/i.test(
                userAgent.toLowerCase()
            )
        
            if (mobile) {
                // 如果userAgent包含上述关键字之一，则认为是在移动设备上
                console.log('访问设备是移动设备')
                this.ismobile = true
            } else {
                // 否则，认为是在PC（桌面设备）上
                console.log('访问设备是PC（桌面设备）')
                this.ismobile = false
            }
        },
        // 苹果下载
        doDownloadPg() {
            window.location.href = 'https://testflight.apple.com/join/DJ2ZgBrG'
        },
        // 安卓端下载
        doDownload() {
            window.location.href = 'https://video.playsstore.cn/download/'
        }
    }
  }
  </script>
  
  <style scoped lang="less">
  .cz_box1{
    width: 100vw;
    height: 100vh;
    background: url('../../../assets/img/index02.png') no-repeat;
    background-size: 100% 100%;
  }
  .cz_box{
    width: 100vw;
    height: 100vh;
    .cz_top{
        padding: 32px 34px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        img{
            width: 130px;
        }
        .cz_but{
            width: 137px;
            height: 54px;
            background: rgba(255,221,153,0.1);
            border-radius: 5px 5px 5px 5px;
            border: 1px solid #FFDD99;
            text-align: center;
            font-family: PingFang SC, PingFang SC;
            font-weight: 500;
            font-size: 18px;
            color: #FFDD99;
            line-height: 54px;
            cursor: pointer;
        }
    }
    .cz_bottom{
        height: calc(100vh - 46px - 64px - 104px);
        margin-top: 46px;
        display: flex;
        justify-content: space-between;
        margin-top: 96px;
        // align-items: center;
        position: relative;
        .right_z{
            img{
                width: 338px;
                position: absolute;
                bottom: 1px;
                right: 225px;
            }
        }
        .left_z{
            padding-left: 164px;
            .left_title{
                img{
                    width: 519px;
                }
            }
            .left_xz{
                margin-top: 86px;
                width: 519px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 0px 100px;
                div img{
                    width: 67px;
                    cursor: pointer;
                }
            }
            .left_ma{
                margin-top: 43px;
                width: 519px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 0px 29px;
                div img{
                    width: 205px;
                    height: 205px;
                }
            }
            .left_pg{
                display: none;
            }
            .left_anz{
                display: none;
            }
        }
    }
  }

  .mob_box{
    width: 100vw;
    height: 100vh;
    background: url('../../../assets/img/sj04.png') no-repeat;
    background-size: 100% 100%;
    .mob_login{
        padding-top: 28px;
        text-align: right;
        span{
            display: inline-block;
            width: 88px;
            height: 36px;
            background: rgba(255,221,153,0.1);
            border-radius: 5px 5px 5px 5px;
            border: 1px solid #FFDD99;
            font-family: PingFang SC, PingFang SC;
            font-weight: 500;
            font-size: 14px;
            color: #FFDD99;
            text-align: center;
            line-height: 36px;
            margin-right: 18px;
        }
    }
    .mob_tu{
        margin-top: 109px;
        img{
            width: 105px;
            margin: auto;
        }
    }
    .mob_an{
        width: 100vw;
        position: fixed;
        bottom: 68px;
        left: 0px;
        div{
            text-align: center;
            margin-top: 23px;
            img{
                width: 162px;
                margin: auto;
            }
        }
    }
  }
  </style>
  